<template>
  <div class="myFans h-full w-full p-8 flex flex-col">
    <SearchTemplate
      :formData="formData"
      labelWidth="80px"
      class="search_fans"
      :placeholderText="placeholder"
      @handleQuery="handleQuery"
      @handleReset="handleReset"
    >
      <template slot="more">
        <el-form-item label="关注日期">
          <el-date-picker
            v-model="joinTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="dateChangeFn"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所在地区">
          <SelectAreaMul @change="areaChangeFn" :areaData="formData.areaArr" />
        </el-form-item>
        <el-form-item label="用户类型">
          <el-select v-model="formData.type" clearable placeholder="用户类型">
            <el-option
              v-for="item in typeOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="持续关注天数" class="focus" label-width="100px">
          <el-input
            class="input-with-select"
            v-model.trim="focusNum"
            type="number"
            :step="1"
            clearable
            :min="0"
            placeholder="天数"
            @input="inputChangeNum"
          >
            <el-select slot="prepend" v-model="focusField" placeholder="请选择">
              <el-option
                v-for="item in focusFieldOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="是否是往来单位" label-width="110px">
          <el-select v-model="formData.isCoop" placeholder="">
            <el-option
              v-for="item in isCooperatorOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </SearchTemplate>
    <el-table
      :data="tableData"
      border
      class="table-base m-t-16"
      size="small"
      style="width: 100%"
      height="400px"
    >
      <el-table-column prop="userName" label="用户名称" min-width="200">
        <template slot-scope="scope">
          <div class="flex justify-around px-8">
            <div class="unit_name">
              <span v-show="scope.row.companyName">
                {{ scope.row.companyName }}-
              </span>
              <span v-show="scope.row.userName">
                {{ scope.row.userName }}
              </span>
            </div>
            <i
              class="iconfont icon-line_message1 cursor-pointer"
              @click="sendMsg(scope.row)"
            ></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        align="center"
        label="联系电话"
        min-width="120"
      >
      </el-table-column>
      <el-table-column
        prop="areaStr"
        align="center"
        label="所在地区"
        min-width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="cooperatorType"
        align="center"
        label="用户类型"
        min-width="150"
      >
        <template slot-scope="scope">
          {{ renderUserType(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="followTime"
        align="center"
        label="关注日期（最新）"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        prop="followDays"
        align="center"
        label="持续关注天数（天）"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        prop="isCoop"
        align="center"
        label="是否是往来单位"
        min-width="150"
      >
        <template slot-scope="scope">
          {{ scope.row.isCoop ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="120">
        <template slot-scope="scope">
          <div v-if="!scope.row.isCoop">
            <el-button type="text" size="mini" @click="settingFn(scope.row)">
              设为往来单位
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="m-t-16 text-right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageInfo.pageSize"
      layout="total,  prev, pager, next, jumper,sizes"
      :total="totalSize"
    >
    </el-pagination>
    <AuditUnitDialog
      ref="setting"
      :rowData="rowData"
      pageType="myFans"
      dialogTitle="设为往来单位"
      @refresh="refreshFn"
    />
  </div>
</template>

<script>
import SearchTemplate from "@/components/search/SearchTemplate.vue";
import SelectAreaMul from "@/components/selectArea/SelectAreaMul.vue";
import AuditUnitDialog from "../components/auditUnitDialog.vue";
import { getCooperatorFansList } from "@/api/cooperator";

const defaultFormData = {
  keyword: "", //# 粉丝用户姓名/电话
  followTimeStart: "", // # 关注时间
  followTimeEnd: "", //# 关注时间
  areaArr: [], //所在地区
  type: "", //# 用户类型
  // followDay: "", //	 # 关注天数：<int64或=int64或>int64
  // focusField: "", //大于、等于、小于
  // focusNum: "",
  isCoop: 0, //# 是否是往来单位:-1:不是,1=是,其他=全部
};

export default {
  components: {
    SearchTemplate,
    SelectAreaMul,
    AuditUnitDialog,
  },
  data() {
    return {
      placeholder: "用户姓名/电话等关键字",
      formData: JSON.parse(JSON.stringify(defaultFormData)),
      joinTime: [],
      typeOptions: [
        { name: "个人", code: "person" },
        { name: "公司-经销商", code: "dealer" },
        { name: "公司-修理厂", code: "garage" },
        { name: "公司-生产厂家", code: "manufacturer" },
      ],
      isCooperatorOptions: [
        { name: "全部", code: 0 },
        { name: "是", code: 1 },
        { name: "否", code: -1 },
      ],
      focusFieldOptions: [
        {
          value: ">",
          label: "大于",
        },
        {
          value: "=",
          label: "等于",
        },
        {
          value: "<",
          label: "小于",
        },
      ],
      focusField: ">", //大于、等于、小于
      focusNum: "0",
      pageInfo: {
        page: 1,
        pageSize: 20,
      },
      totalSize: 0,
      tableData: [
        // {
        //   id: 0, //# 主键ID
        //   userId: 0, //# 用户ID
        //   storeId: 0, //# 关注的店铺ID（公司ID）
        //   createdBy: 0, //# 创建者员工ID
        //   userName: "张三", //# 用户名称
        //   phone: "13422222222", //# 电话
        //   areaId: 0, // # 地区ID
        //   companyId: 0, // # 粉丝公司ID
        //   companyName: "", //	 # 粉丝公司名称
        //   isCoop: false, //# 粉丝是不是我的往来单位
        //   areaStr: "四川省/成都市/双流区", // # 粉丝地址(省市区)
        //   followDays: 2, // # 关注天数
        //   followAt: "2022-07-15 12:12:12",
        // },
      ],
      rowData: {},
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    inputChangeNum(val) {
      if (val.indexOf("-") > -1) {
        val = val.replace('-','');
      }
      this.focusNum = Math.round(val);
    },
    renderUserType(item) {
      let str = "";
      if (item.companyId) {
        if (item.companyType == "dealer") {
          str = "公司-经销商";
        } else if (item.companyType == "garage") {
          str = "公司-修理厂";
        } else if (item.companyType == "manufacturer") {
          str = "公司-生产厂家";
        }
      } else {
        str = "个人";
      }
      return str;
    },
    sendMsg(e) {
      console.log(e);
      this.$store.dispatch("setChatId", e.userId);
      this.$store.dispatch("changeIMVisible", true);
    },
    refreshFn() {
      this.pageInfo.page = 1;
      this.initData();
    },
    initData() {
      let params = {
        ...this.formData,
        followDay: this.focusField + this.focusNum,
        ...this.pageInfo,
      };
      getCooperatorFansList(params).then((res) => {
        this.tableData = res?.rows || [];
        this.totalSize = res?.totalSize;
      });
    },
    handleQuery() {
      this.pageInfo.page = 1;
      this.initData();
    },
    handleReset() {
      this.formData = JSON.parse(JSON.stringify(defaultFormData));
      this.focusField = ">"; //大于、等于、小于
      this.focusNum = "0";
      this.joinTime = [];
    },
    areaChangeFn(data) {
      // console.log("area", data);
      this.formData.areaArr = data;
    },
    dateChangeFn(date) {
      if (date) {
        this.formData.followTimeStart = date[0];
        this.formData.followTimeEnd = date[1];
      } else {
        this.formData.followTimeStart = "";
        this.formData.followTimeEnd = "";
      }
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.initData();
    },
    settingFn(row) {
      console.log(row);
      this.rowData = row;
      this.$refs.setting.show();
    },
  },
};
</script>

<style lang="less" scoped>
.myFans {
  .search_fans {
    .focus {
      /deep/ .input-with-select {
        width: 204px;
        display: flex;
        & > .el-input-group__prepend {
          width: 86px;
          padding: 0;
          & > .el-select {
            width: 86px;
            margin: 0;
            box-sizing: border-box;
            border-right: 1px solid #dcdfe6;
            &:last-child {
              border-right: none;
            }
            .el-input {
              width: auto;
            }
            .el-input--small .el-input__inner {
              height: 30px;
              line-height: 30px;
            }
          }
        }
        & > .el-input__inner {
          flex: 1;
        }
        & > .el-input__suffix {
          display: none;
        }
      }
    }
  }

  .unit_name {
    color: #6589f8;
  }
  .not_associat {
    display: inline-block;
    margin-top: 4px;
    color: #666666;
    padding: 0px 4px;
    border: 1px solid #999999;
    border-radius: 4px;
    background: #f5f5f5;
  }
}
</style>
